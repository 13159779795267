<template>
  <v-app>
    <div id="body">
      <v-sheet>
        <v-carousel hide-delimiters height="100%" width="100%">
          <template #prev="{ on, attrs }">
            <svg id="left" viewBox="0 0 48 42">
              <path v-bind="attrs" d="M 24 0 L 48 42 L 0 42 Z" fill="#fff" v-on="on"></path>
            </svg>
          </template>
          <template #next="{ on, attrs }">
            <svg id="right" viewBox="0 0 48 42">
              <path v-bind="attrs" d="M 24 0 L 48 42 L 0 42 Z" fill="#fff" v-on="on"></path>
            </svg>
          </template>
          <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.load">
            <div id="title1" class="d-flex justify-center align-center">
              <span id="text_title1" class="d-flex justify-center align-center"
                >何かが<br />起きたとしても<br />美味しいものは<br />食べたくなる。</span
              >
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
      <v-sheet height="925px" style="position: relative">
        <div style="position: absolute; top: 0; left: 0; min-width: 400px">
          <span id="back1">災害</span>
        </div>
        <div class="d-flex justify-center" style="padding-top: 100px; margin-right: 450px">
          <span id="title2">いざという時に<br />美味しい備え。</span>
        </div>
        <div class="d-flex justify-center">
          <div style="margin-top: -300px; margin-left: 750px">
            <v-img id="img1" src="./images/home/n_21122_bimistock_image_01.png"></v-img>
          </div>
        </div>
        <div class="d-flex justify-center">
          <div style="margin-top: -150px; margin-right: 650px">
            <v-img id="img2" src="./images/home/n_3258.png"></v-img>
          </div>
        </div>
        <div width="100%" style="text-align: center" class="mt-3">
          <span id="text1">
            BimiStock ビミストック は、３か月ごとに、<br />選りすぐりの美味しい保存食をお届けする、<br />手間いらずの防災食定期便です。
          </span>
        </div>
        <div style="position: absolute; bottom: 200px; right: 0">
          <span id="back2">in case of<br />emergency</span>
        </div>
        <div class="d-flex justify-center">
          <div style="width: 1366px; margin-top: 50px">
            <v-img src="../assets/images0116/pc/file-----3288@1x.png"></v-img>
          </div>
        </div>
      </v-sheet>
      <v-sheet>
        <v-img class="bg1" src="./images/home/n_21122_bimistock_image_11.png" style="position: relative">
          <div class="m" style="position: absolute; bottom: 350px; left: 35px; z-index: 3">
            <span>Merit</span>
          </div>
          <div class="m_0" style="position: absolute; bottom: 45px; left: 25px; z-index: 2">
            <span>01</span>
          </div>
          <div style="position: absolute; bottom: 70px; left: 90px; z-index: 4">
            <div class="m_title">
              <span>手間いらずで簡単に防災食を備蓄できます</span>
            </div>
            <div class="m_text" style="margin-top: 15px">
              <span
                >３か月ごとに新しい備蓄用の保存食セットを自動的にお届けします。仕事や子育てに忙しいご家庭でも、消費期限の管理や入れ替えに時間・労力をかけることなく災害に備えていただけます。</span
              ><br />
            </div>
          </div>
          <svg class="svg" style="position: absolute; bottom: 40px; left: 60px; z-index: 1">
            <rect class="rect" rx="0" ry="0" x="0" y="0"></rect>
          </svg>
        </v-img>
      </v-sheet>
      <v-sheet>
        <v-img class="bg1" src="./images/home/n_21122_bimistock_image_12_.png" style="position: relative">
          <div class="m" style="position: absolute; bottom: 350px; right: 35px; z-index: 3">
            <span>Merit</span>
          </div>
          <div class="m_0" style="position: absolute; bottom: 45px; right: 25px; z-index: 2">
            <span>02</span>
          </div>
          <div style="position: absolute; bottom: 70px; right: 75px; z-index: 4">
            <div class="m_title">
              <span>災害時でも、食べなれたいつもの美味しい食事ができます</span>
            </div>
            <div class="m_text" style="margin-bottom: 15px">
              <span
                >日本全国から集めた、バリエーション豊かで美味しい、常温保存食品をお届けします。美味しい食事は、お腹を満たすだけでなく、不安な気持ちやストレスを和らげることも期待できます。特にお子さまには少しでも安心感を与えてあげたいですよね。</span
              ><br />
            </div>
          </div>
          <svg class="svg" style="position: absolute; bottom: 40px; right: 60px; z-index: 1">
            <rect class="rect" rx="0" ry="0" x="0" y="0"></rect>
          </svg>
        </v-img>
      </v-sheet>
      <v-sheet>
        <v-img class="bg1" src="../assets/images0116/pc/menu/221122_bimistock_image_14.png" style="position: relative">
          <div class="m" style="position: absolute; top: 15px; left: 35px; z-index: 3">
            <span>Merit</span>
          </div>
          <div class="m_0" style="position: absolute; top: -55px; left: 25px; z-index: 2">
            <span>03</span>
          </div>
          <div style="position: absolute; top: 145px; left: 90px; z-index: 4">
            <div class="m_title">
              <span>普段の食卓での一品や忙しい時の時短料理として</span>
            </div>
            <div class="m_text" style="margin-bottom: 15px">
              <span
                >普段使い用として作られた、美味しくて上質な保存食品をお届けします。３か月の備蓄期間が過ぎた後は、毎日の食卓のひと品や時短料理としてお使いください。防災専用備蓄食のような追加費用ではなく、月々の食費の一部で防災対策ができるというイメージです。</span
              ><br />
            </div>
          </div>
          <svg class="svg" style="position: absolute; top: 125px; left: 60px; z-index: 1">
            <rect class="rect" rx="0" ry="0" x="0" y="0"></rect>
          </svg>
        </v-img>
      </v-sheet>
      <v-sheet class="toSet" height="80px" color="#ff6e40">
        <div class="d-flex justify-center pt-1" @click="set">
          <div class="bar">
            <span class="taste" @click="set">お試しセットを申し込む</span>
          </div>
        </div>
      </v-sheet>
      <v-sheet height="2000px" style="position: relative; z-index: 1">
        <div class="toSet">
          <v-sheet
            height="80px"
            color="rgba(0,0,0,0)"
            style="position: absolute; top: -80px; width: 100%; z-index: 3"
            @click="set"
          >
          </v-sheet>
        </div>
        <div style="position: absolute; top: 110px; left: 0; min-width: 400px; z-index: 2">
          <span class="back3">備蓄</span>
        </div>
        <div
          width="100%"
          style="position: absolute; top: 45px; left: 0; right: 0; margin: auto; width: 950px; z-index: 99"
        >
          <span id="title3">届く・備える・食べる</span>
        </div>
        <div style="position: absolute; top: 195px; left: 0; right: 0; margin: auto; width: 1260px; z-index: 99">
          <div class="text2">
            <span
              >災害時、ご自身や大切なご家族を守るためには、最低３日分の備えが必要です！
              <br />３か月ごとに手間いらずで届く BimiStock ビミストックで<br />政府も推奨する</span
            >
            <span
              style="color: #ff6e40; text-decoration: underline"
              @mouseenter="showText1()"
              @mouseleave="showText2()"
            >
              ローリングストック
            </span>
            <span
              v-if="show === 1"
              id="tooltip"
              style="position: absolute; top: 185px; left: 0; right: 0; margin: auto; width: 676px; z-index: 99"
            >
              ※ローリングストックとは、日常的に非常食を食べ、食べた分を買い足すというサイクルを繰り返すことで常に新しい食料を備蓄する方法です。廃棄ロスの観点からも注目されてます。
            </span>
            <span>をはじめましょう</span>
          </div>
        </div>
        <div style="position: absolute; top: 440px; right: 0; min-width: 400px; max-height: 200px; z-index: 1">
          <span class="back3">防災食</span>
        </div>
        <div
          class="mark"
          style="position: absolute; top: 500px; left: 0; right: 0; width: 121px; margin: auto; z-index: 4"
        >
          <span>備える</span>
        </div>
        <div
          class="mark"
          style="position: absolute; top: 1025px; left: 0; right: 0; width: 81px; margin: auto; z-index: 4"
        >
          <span style="margin-left: -300px">届く</span>
        </div>
        <div
          class="mark"
          style="position: absolute; top: 1025px; left: 0; right: 0; width: 121px; margin: auto; z-index: 4"
        >
          <span style="margin-left: 303px">食べる</span>
        </div>
        <div style="position: absolute; top: 430px; left: 0; right: 0; width: 924.609px; margin: auto; z-index: 3">
          <v-img
            id="img3"
            alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
            src="./images/home/n_3238.png"
          >
            <div style="position: absolute; top: 400px; left: 0; right: 0; width: 200px; margin: auto; z-index: 99">
              <v-img
                alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                style="width: 200px"
                src="./images/home/n_3236.png"
              ></v-img>
            </div>
          </v-img>
        </div>
        <div style="position: absolute; bottom: 355px; left: 0; right: 0; width: 1421px; margin: auto; z-index: 2">
          <span id="back4">Rolling<br />Stock</span>
        </div>
        <div
          style="
            position: absolute;
            bottom: 270px;
            right: 0;
            left: 0;
            text-align: center;
            width: 1197px;
            margin: auto;
            z-index: 5;
          "
        >
          <span id="text3"
            >地震・台風・暴風・大雨・大雪・噴火、そして新型コロナをはじめとする感染症など、近年日本各地で災害が頻発しています。３０年以内に南海トラフ大規模地震が発生する確率は７０～８０％と言われています。災害時、公的な支援物資はすぐに届かないかもしれません。コンビニなどのお店にも人が殺到し、すぐに商品が無くなるかもしれません。各家庭で最低でも３日分の食料備蓄が必要なのです。<br />３か月ごとに、全国から集めた美味しい保存食が届く
            BimiStock なら<br />「いざという時に消費期限切れ」や「慣れない味で食べづらい」といった心配は無用です。<br />新しい保存用セットが届いたら、３か月間保存していた食品を普段の食卓で美味しく食べる。<br />そんな
            BimiStock の新発想ローリングストックでご自身やご家族のためにしっかり備えましょう！</span
          >
        </div>
        <div
          style="
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;
            width: 100%;
            margin: auto;
            z-index: 1;
          "
        >
          <v-img src="./images/home/AdobeStock_129693242.png"></v-img>
        </div>
      </v-sheet>
      <v-sheet>
        <v-img class="bg2" src="./images/home/n_21122_bimistock_image_03.png" style="position: relative">
          <div width="100%" style="text-align: center; padding-top: 40px">
            <span id="title4">３食×３日分をお届け</span>
          </div>
          <div width="100%" style="text-align: center; padding-top: 15px">
            <span id="text4"
              >１日３食×３日分の美味しい保存食を３か月ごとにお届けします。<br />９食全部が違う料理なので、飽きることなくお食事を楽しめます。</span
            >
          </div>
          <div class="d-flex justify-center btn1">
            <v-btn depressed class="mr-8" color="#ff6e40" width="630px" min-height="75px" @click="subscription">
              <span class="btn_t1">今シーズンの定期便商品を見る</span>
            </v-btn>
            <v-btn depressed color="#ff6e40" width="630px" min-height="75px" @click="set">
              <span class="btn_t1">お試しセットを申し込む</span>
            </v-btn>
          </div>
        </v-img>
      </v-sheet>
      <v-sheet height="1150px" style="position: relative">
        <div style="position: absolute; top: 0px; left: 0; right: 0; width: 1421px; margin: auto; z-index: 1">
          <span id="back5">Delicious Preservative Foods</span>
        </div>
        <div width="100%" style="text-align: center; padding-top: 75px">
          <span id="title5">普段使いのアレンジレシピ</span>
        </div>
        <div width="100%" style="text-align: center; padding-top: 10px">
          <span id="text5">BimiStockの商品を使ったワンランク上のアレンジレシピをご紹介します。</span>
        </div>
        <div class="img4" style="z-index: 2">
          <v-img
            width="350px"
            style="margin-left: -390px"
            src="./images/home/n_21122_bimistock__arrange.png"
            class="pointer"
            @click="toRecipeScroll('1')"
          ></v-img>
          <div class="name" style="margin-left: -390px; margin-top: -15px">
            <span>らふてー三つ葉うどん</span>
          </div>
          <v-btn class="btn2 mt-3" tile style="margin-left: -390px" depressed outlined @click="toRecipeScroll('1')">
            <span class="btn_t2">レシピを見る</span>
          </v-btn>
        </div>
        <div class="img4" style="z-index: 4">
          <v-img
            width="350px"
            src="./images/home/n_21122_bimistock__arrange_bv.png"
            class="pointer"
            @click="toRecipeScroll('2')"
          ></v-img>
          <div class="name" style="margin-top: -15px">
            <span>ガリバタキャベツスープカレー</span>
          </div>
          <v-btn class="btn2 mt-3" tile depressed outlined @click="toRecipeScroll('2')">
            <span class="btn_t2">レシピを見る</span>
          </v-btn>
        </div>
        <div class="img4" style="z-index: 2">
          <v-img
            width="350px"
            style="margin-left: 390px"
            src="./images/home/n_21122_bimistock__arrange_b.png"
            class="pointer"
            @click="toRecipeScroll('3')"
          ></v-img>
          <div class="name" style="margin-left: 390px; margin-top: -15px">
            <span>チンして混ぜるだけステーキペッパーライス</span>
          </div>
          <v-btn class="btn2 mt-3" tile style="margin-left: 390px" depressed outlined @click="toRecipeScroll('3')">
            <span class="btn_t2">レシピを見る</span>
          </v-btn>
        </div>
        <div style="position: absolute; bottom: 100px; right: 0; left: 0; width: 306px; margin: auto; z-index: 5">
          <v-img style="margin-left: -412px; width: 306px; height: 306px" src="./images/home/imaizumi.png"></v-img>
        </div>
        <div
          id="imaizumi"
          style="position: absolute; bottom: 100px; right: 0; left: 0; width: 773.5px; margin: auto; z-index: 5"
        >
          <div style="margin-left: 180px; width: 773.5px">
            <div class="text6" style="font-size: 20px">アレンジレシピを考案「防災クッキング」でもおなじみ</div>
            <div class="my-3">
              <span class="text6" style="font-size: 28px">管理栄養士 今泉マユ子さん</span>
              <a
                href="https://office-rm.com/wp/?page_id=17"
                target="_blank"
                class="text6"
                style="text-decoration: none; margin-left: 230px"
              >
                ▶︎今泉マユ子さんについて
              </a>
            </div>
            <div class="text6" style="font-size: 14px; font-weight: 400">
              BimiStockは、全国の美味しい保存食品が届くので発見と楽しさがいっぱいです。届いたとき「何が入っているだろう」とワクワク、箱を開けたとき「すごい!
              こんな食品もあるのね」とワクワク、食べて「美味しい！」とワクワク、「次はどんなのが届くかな」と、ワクワクの連続♪
              新しい味との出会い、美味しい食事は心を豊かにしてくれますよね。<br />
              時には、ゆずこしょう、ラー油、酢、スパイスなどの調味料で味変したり、トッピングなどをちょい足しして、自分の好きな味に変えてみて下さい。野菜をプラス、食感をプラスするのもおススメです。<br />
              災害時に食べる食事は、決して“我慢して”食べるものではありません。ストレスが続く災害時の生活において日常を取り戻せる”いつもの味”は、家族みんなの心をほっとさせてくれるでしょう。3か月ごとに届くBimiStockは、食料を無駄にせずに災害に備えることができるローリングストックを手軽にできる点がいいですね。「届く・備える・食べる」を習慣化して、いざという時も憂いなしですね。
            </div>
          </div>
        </div>
        <div style="position: absolute; bottom: -110px; left: 0; right: 0; width: 1291px; margin: auto; z-index: 1">
          <span id="back6">美味しい食卓</span>
        </div>
      </v-sheet>
      <v-sheet>
        <v-img width="100%" src="./images/home/AdobeStock_316641157.png" style="position: relative">
          <div style="text-align: center; margin-left: 650px; padding-top: 75px">
            <span id="title6">いざという時にも<br />美味しいごはんを</span>
          </div>
          <div class="d-flex justify-center" style="padding-top: 20px">
            <span id="text7" style="margin-left: 650px">
              BimiStock ビミストック は、３か月ごとに、<br />選りすぐりの美味しい保存食をお届けする、<br />手間いらずの防災食定期便です。
            </span>
          </div>
          <div style="position: absolute; top: 390px; left: 0; right: 0; width: 393px; margin: auto">
            <v-img style="width: 393px; height: 97.556px; margin-left: 260px" src="./images/home/n_3258_es.png"></v-img>
          </div>
          <div
            style="
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 75px;
              z-index: 99;
              background-color: rgba(255, 104, 47, 0.73);
            "
            class="toSet"
            @click="set"
          >
            <div class="bar d-flex justify-center">
              <span class="taste" @click="set">お試しセットを申し込む</span>
            </div>
          </div>
        </v-img>
      </v-sheet>
      <v-sheet style="position: relative; margin-top: -50px">
        <div width="100%" style="text-align: center" class="mt-12 pt-8">
          <span id="back7">Notification</span>
        </div>
        <div width="100%" style="text-align: center; margin-top: -110px">
          <span id="title7">お知らせ</span>
        </div>
        <div
          class="mx-"
          style="position: absolute; top: 250px; right: 0; left: 0; max-width: 1232px; margin: auto; z-index: 5"
        >
          <div class="text8 my-6 d-flex justify-center">
            <span>2023年3月11日サービスを開始致しました。</span>
          </div>
        </div>
        <div width="100%" style="text-align: center; margin-top: 190px">
          <span id="back8">Disaster Prevenation</span>
        </div>
        <div width="100%" style="text-align: center; margin-top: -110px">
          <span id="title9">防災をもっと知ろう！防災リンク集</span>
        </div>
        <div class="d-flex justify-center align-center my-12 py-12">
          <div class="pointer mb-12">
            <v-img
              style="width: 383px; height: 132px"
              src="./images/home/AdobeStock_507199001_Preview.png"
              onclick="window.open('https://www.gov-online.go.jp/tokusyu/cu_bosai/index.html')"
            >
              <div style="text-align: center; margin-top: 50px">
                <span id="tip">防災・減災お役立ち情報</span>
              </div>
            </v-img>
          </div>
          <div class="mx-12 px-12 pointer mb-12">
            <v-img
              style="width: 236px; height: 132px"
              src="./images/home/n_1_et.png"
              onclick="window.open('https://www.jma.go.jp/jma/menu/menuflash.html')"
            ></v-img>
          </div>
          <div class="pointer mb-12">
            <v-img
              style="width: 447px; height: 76px"
              src="./images/home/n_2_eu.png"
              onclick="window.open('https://www.bousai.go.jp/oyakudachi/info_general.html')"
            ></v-img>
          </div>
        </div>
      </v-sheet>
      <div class="toSet">
        <v-sheet height="80px" color="#ff6e40" style="margin-top: -50px">
          <div class="d-flex justify-center pt-1" @click="set">
            <div>
              <span class="taste" @click="set">お試しセットを申し込む</span>
            </div>
          </div>
        </v-sheet>
      </div>
      <v-sheet height="2px" color="#fff"></v-sheet>
    </div>
  </v-app>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    size: 0,
    show: 0,
    List: [],
    items: [
      {
        load: require('./images/home/n_40.png'),
      },
      {
        load: require('./images/home/n_40_n.png'),
      },
      {
        load: require('./images/home/n_40.png'),
      },
      {
        load: require('./images/home/n_40_n.png'),
      },
    ],
  }),
  computed: {
    ...mapState('subscriptionStore', ['subscriptionList']),
  },
  mounted() {
    window.addEventListener('load', this.getWidth())
    window.addEventListener('resize', this.getWidth)
    this.toPoint()
  },
  methods: {
    ...mapActions('subscriptionStore', ['loadSubscriptionList']),

    toPoint() {
      const key = this.$route.query.Anchor
      if (key === '0') {
        const bookMark = document.getElementById('title1')
        bookMark.scrollIntoView()
      }
      if (key === '1') {
        const bookMark = document.getElementById('title2')
        bookMark.scrollIntoView()
      }
      if (key === '2') {
        const bookMark = document.getElementById('title5')
        bookMark.scrollIntoView()
      }
      if (key === '3') {
        const bookMark = document.getElementById('title7')
        bookMark.scrollIntoView()
      }
      if (key === '4') {
        const bookMark = document.getElementById('title3')
        bookMark.scrollIntoView()
      }
      if (key === '5') {
        const bookMark = document.getElementById('imaizumi')
        bookMark.scrollIntoView()
      }
    },
    getWidth() {
      if (window.innerWidth <= 1200) {
        this.size = 1
      } else {
        this.size = 2
      }
    },
    showText1() {
      this.show = 1
    },
    showText2() {
      this.show = 0
    },
    subscription() {
      // this.loadSubscriptionList(this.$route.params).then(() => {
      //   this.List = [...this.subscriptionList]
      //   this.List.forEach((sl, i) => {
      //     console.log(this.List[i])

      //     if (Date.now() > Date.parse(sl.subs_ssd) && Date.now() < Date.parse(sl.subs_sed)) {
      //       this.$router.push({
      //         name: 'subscription',
      //         params: {
      //           id: sl.subs_id,
      //         },
      //       })
      //     }
      //   })
      // })

      this.$router.push('/subscriptionlist')
    },
    set() {
      this.$router.push('/setlist')
    },
    toRecipeScroll(val) {
      this.$router.push({
        path: '/recipe',
        query: {
          Anchor: val,
        },
      })
    },
  },
}
</script>
<style scoped>
#body {
  min-width: 1240px;
  align-items: center;
}
#left {
  position: absolute;
  left: 15px;
  top: 0;
  width: 48px;
  height: 42px;
  transform: rotate(-90deg);
  transform-origin: center;
  cursor: pointer;
}
#right {
  position: absolute;
  right: 15px;
  top: 0;
  width: 48px;
  height: 42px;
  transform: rotate(90deg);
  transform-origin: center;
  cursor: pointer;
}
#title1 {
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.706));
  height: 100%;
  line-height: 172px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 142px;
  color: #fff;
}
#back1 {
  opacity: 0.08;
  line-height: 420px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 498px;
  color: black;
}
#title2 {
  width: 666px;
  white-space: nowrap;
  line-height: 116px;
  margin-top: -10.5px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 95px;
  color: black;
}
#img1 {
  height: 461px;
  width: 780px;
}
#img2 {
  height: 117.131px;
  width: 471.857px;
}
#text1 {
  width: 850px;
  line-height: 60px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: black;
}
#back2 {
  opacity: 0.08;
  line-height: 150px;
  white-space: nowrap;
  text-align: left;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 198px;
  color: black;
}
#Read_More {
  line-height: 60px;
  text-align: center;
  font-family: Helvetica;
  font-weight: 900;
  font-size: 20px;
  color: #fff;
}
.bg1 {
  width: 100%;
  min-height: 600px;
}
.svg {
  width: 695px;
  height: 308px;
}
.rect {
  opacity: 0.708;
  fill: #fff;
  width: 695px;
  height: 308px;
}
.m {
  opacity: 0.594;
  white-space: nowrap;
  text-align: left;
  font-family: Helvetica;
  font-style: italic;
  font-weight: bold;
  font-size: 69px;
  color: #fff;
  text-transform: capitalize;
}
.m_0 {
  opacity: 0.5;
  mix-blend-mode: multiply;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 343px;
  color: rgba(255, 104, 47, 1);
  text-transform: uppercase;
}
.m_title {
  width: 650px;
  height: 160px;
  line-height: 60px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 45px;
  color: black;
}
.m_text {
  width: 650px;
  height: 80px;
  line-height: 25px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: black;
}
.taste {
  width: 600px;
  height: 80px;
  line-height: 68px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  color: #fff;
}
.bar :hover {
  cursor: pointer;
}
.back3 {
  opacity: 0.08;
  line-height: 120px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 401px;
  color: black;
}
#title3 {
  white-space: nowrap;
  line-height: 116px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 95px;
  color: #ff6e40;
}
.text2 {
  width: 1260px;
  line-height: 60px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 35px;
  color: black;
}

#tooltip {
  width: 676px;
  height: 48px;
  line-height: 20px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 15px;
  color: black;
}
.mark {
  white-space: nowrap;
  line-height: 48px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: rgba(118, 118, 118, 1);
}
#img3 {
  height: 844.176px;
  width: 924.609px;
}
#back4 {
  opacity: 0.08;
  line-height: 380px;
  white-space: nowrap;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 419px;
  color: black;
}
#text3 {
  line-height: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: black;
}
.bg2 {
  width: 100%;
  height: 100%;
}
#title4 {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.325));
  width: 1211px;
  white-space: nowrap;
  line-height: 153px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 121px;
  color: #fff;
}
#text4 {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.325));
  width: 1047px;
  white-space: nowrap;
  line-height: 53px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  color: #fff;
}
.btn1 {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
}
.btn_t1 {
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 3px;
  font-size: 30px;
  color: #fff;
}
#back5 {
  opacity: 0.08;
  line-height: 180px;
  white-space: nowrap;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 95px;
  color: black;
}
#title5 {
  width: 1057px;
  white-space: nowrap;
  line-height: 54px;
  margin-top: -3.5px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 47px;
  color: black;
}
#text5 {
  width: 1057px;
  white-space: nowrap;
  line-height: 54px;
  margin-top: -3.5px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 31px;
  color: black;
}
.img4 {
  position: absolute;
  top: 260px;
  left: 0;
  right: 0;
  width: 350px;
  margin: auto;
}
.name {
  white-space: nowrap;
  line-height: 60px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(53, 78, 87, 1);
}
.btn2 {
  height: 35px;
  width: 350px;
  color: rgba(53, 78, 87, 1);
}
.btn_t2 {
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: rgba(53, 78, 87, 1);
}
.text6 {
  line-height: 25px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: black;
}
#back6 {
  opacity: 0.08;
  width: 1291px;
  line-height: 380px;
  white-space: nowrap;
  text-align: center;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 215px;
  color: black;
}
#title6 {
  white-space: nowrap;
  line-height: 81px;
  margin-top: -8px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 65px;
  color: black;
}
#text7 {
  max-width: 576px;
  white-space: nowrap;
  line-height: 35px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: black;
}
#back7 {
  opacity: 0.08;
  width: 699px;
  white-space: nowrap;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 127px;
  color: rgba(0, 0, 0, 1);
}
#title7 {
  width: 180px;
  white-space: nowrap;
  line-height: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  color: rgba(0, 0, 0, 1);
}
.text8 {
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: black;
}
#title9 {
  width: 721px;
  white-space: nowrap;
  line-height: 54px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  color: black;
}

#back8 {
  opacity: 0.08;
  width: 1194px;
  white-space: nowrap;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 127px;
  color: black;
}
#tip {
  width: 331px;
  white-space: nowrap;
  line-height: 37px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  color: #fff;
}
.pointer :hover {
  cursor: pointer;
}
.toSet :hover {
  cursor: pointer;
}
</style>
